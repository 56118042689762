import React from 'react';
import './AccountDeletionRequest.scss';

const AccountDeletionRequest = () => {
    return (
        <div className="account-deletion-request">
            <div className="content">
                <h1>Account Deletion Request</h1>
                <div className="info-box">
                    <p>Before proceeding with account deletion, please note:</p>
                    <ul>
                        <li>This action cannot be undone</li>
                        <li>All your data will be permanently removed</li>
                        <li>You'll lose access to all services associated with this account</li>
                        <li>Data will be deleted from our servers within 90 days</li>
                    </ul>
                </div>
                <div className="form-container">
                    <iframe 
                        src="https://forms.gle/L3ZmFr7i2oBVyxsA6" 
                        width="100%" 
                        height="700" 
                        frameBorder="0" 
                        marginHeight="0" 
                        marginWidth="0"
                        title="Account Deletion Form"
                        loading="lazy"
                    >
                        Loading…
                    </iframe>
                </div>
            </div>
        </div>
    );
};

export default AccountDeletionRequest;