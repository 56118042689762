// src/App.js
import './App.css';
import Home from './pages/home/home';
import { createBrowserRouter, RouterProvider, Outlet, useLocation } from "react-router-dom";
import { useEffect } from 'react';
import Footer from './components/footer/Footer';
import Aboutus from './pages/aboutus/Aboutus';
import HeroSection from './components/heroSection/HeroSection';
import Version from './pages/version/Version';
import Team from './pages/team/Team';
import Contactus from './pages/contactus/Contactus';
import Privacy from './pages/privacyandpolicy/Privacy';
import Terms from './pages/termsandconditions/Terms';
import { logPageView } from './analytics';
import AccountDeletionRequest from './pages/accountdeletionrequest/AccountDeletionRequest';

function Layout() {
  const location = useLocation();

  useEffect(() => {
    logPageView();
  }, [location]);

  return (
    <div className="app">
      <HeroSection />
      <Outlet />
      <Footer />
    </div>
  );
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      { path: "/", element: <Home /> },
      { path: "aboutus", element: <Aboutus /> },
      { path: "version", element: <Version /> },
      { path: "team", element: <Team /> },
      { path: "contactus", element: <Contactus /> },
      { path: "termsandconditions", element: <Terms /> },
      { path: "privacyandpolicy", element: <Privacy /> },
      { path: "accountdeletionrequest", element: <AccountDeletionRequest /> }  // Add this line
    ]
  }
]);

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
